import React from "react"
import "../wdyr"
import Register from "../components/user/register"
import { isLoggedIn } from "../services/auth"
import { navigate } from "gatsby"
import SEO from "../components/seo"

const RegisterPage = props => {
  if (isLoggedIn()) {
    navigate(`/dashboard`)
    return false
  }

  return (
    <>
      <SEO title="Register" />
      <Register />
   </>
  )
}

export default RegisterPage
